import React, { useState, useEffect, useRef } from "react";
import Modal from "../components/Modal";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Search,
    Sort,
    PdfExport,
    ExcelExport,
    Inject,
    Toolbar,
    Filter,
    DetailRow
} from "@syncfusion/ej2-react-grids";
import { Header } from "../components";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { HiOutlineEye } from "react-icons/hi2";
import { BASE_URL } from "../api/apiConfig";
import { SiStatuspal } from "react-icons/si";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const amountData = [300, 400, 600, 800];

const Contractor = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [selectedUserId, setSelectedUserId] = useState(null);
    let gridInstance = useRef(null);

    const fetchEmployerData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/contractor/getcontractors`);
            if (!response.ok) {
                throw new Error("Failed to fetch user data");
            }
            const data = await response.json();

            const sortedData = data.contractor.sort((a, b) => {
                // Handle cases where status is undefined or null
                if (!a.status) return 1;
                if (!b.status) return -1;

                if (a.status.toLowerCase() === "closed" && b.status.toLowerCase() !== "closed") {
                    return 1;
                }
                if (a.status.toLowerCase() !== "closed" && b.status.toLowerCase() === "closed") {
                    return -1;
                }

                // Default sort by createdAt descending
                return new Date(b.createdAt) - new Date(a.createdAt);
            });

            setUserData(sortedData);
            setLoading(false);
            console.log("Fetched user data:", data.contractor);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployerData();
    }, []);

    useEffect(() => {
        if (isModalOpen === false && selectedUserId) {
            fetchEmployerData();
            setSelectedUserId(null);
        }
    }, [isModalOpen, selectedUserId]);

    const handleStatusChange = async (id, status) => {
        try {
            await handleStatusToChange({ id, status });
            const updatedUserData = userData.map((user) =>
                user._id === id ? { ...user, status: status } : user
            );
            setUserData(updatedUserData);
            setIsModalOpen(false);
            Swal.fire({
                icon: "success",
                title: "Status Updated",
            });
        } catch (error) {
            console.error("Error updating status:", error);
            alert("Failed to update status. Please try again later.");
        }
    };

    const handleStatusToChange = async ({ id, status }) => {
        try {
            const response = await fetch(`${BASE_URL}/contractor/signup/${id}/statusContractor`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ status }),
            });

            if (!response.ok) {
                throw new Error("Failed to update status");
            }
            // Refresh data after successful update
            fetchEmployerData();
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    const handleApproveUser = async ({ _id, isApproved, amount }) => {
        console.log("Approve status:", isApproved);
        console.log(`User ${_id} amount changed to ${amount}`);

        try {
            const response = await fetch(`${BASE_URL}/contractor/approve-contractor/${_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ isApproved, amount }),
            });

            if (!response.ok) {
                throw new Error("Failed to update contractor");
            }

            const updatedUserData = await response.json();

            Swal.fire({
                icon: "success",
                title: "Contractor Approved Successfully",
                text: `Assigned Contractor ID: ${updatedUserData.user._id}`,
            });

            await fetchEmployerData();
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error updating contractor:", error);
        }
    };

    const ActionButtonsTemplate = ({ _id }) => {
        const handleOpenModal = async () => {
            setSelectedUserId(_id);
            try {
                const response = await fetch(`${BASE_URL}/contractor/getcontractors`);
                if (!response.ok) {
                    throw new Error("Failed to fetch contractor data");
                }
                const data = await response.json();
                setModalContent({
                    title: "Contractor List",
                    content: (
                        <div className="overflow-x-auto">
                            <GridComponent dataSource={data.userContractors} allowPaging={true} toolbar={["Search"]}>
                                <ColumnsDirective>
                                    <ColumnDirective field="photo" width="150" headerText="Photo" template={(props) => <img src={props.photo} alt="Contractor" className="w-10 h-10 rounded-full" />} />
                                    <ColumnDirective field="name" headerText="Name" width="150" />
                                    <ColumnDirective field="mobileNumber" headerText="Mobile" width="150" />
                                    <ColumnDirective field="address.city" headerText="Location" width="150" />
                                    <ColumnDirective field="categoriesOfWork" headerText="Skills" width="150" template={(props) => props.categoriesOfWork && props.categoriesOfWork.length > 0 ? props.categoriesOfWork[0] : ''} />
                                    <ColumnDirective headerText="Labour Card" width="150" template={(props) => <a href={props.laborCard} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "500", color: "blue" }}>View</a>} />
                                    <ColumnDirective headerText="Aadhar Card" width="150" template={(props) => <a href={props.aadharCard} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "500", color: "green" }}>View</a>} />
                                    <ColumnDirective field="rating" headerText="Ratings" width="150" />
                                    {/* <ColumnDirective headerText="Amount" width="150" template={(props) => (
                                        <DropDownListComponent id={`ddlelement_${props._id}`} dataSource={amountData} placeholder="Select Amount" value={props.amount} popupHeight="200px" />
                                    )} /> */}
                                    <ColumnDirective headerText="Amount" width="150" template={(props) => (
                                        <input
                                            type="number"
                                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                                            value={props.amount}
                                            onChange={(e) => handleApproveUser({ _id: props._id, amount: e.target.value })}
                                        />
                                    )} />
                                    <ColumnDirective headerText="Assign" width="150" template={(props) => <ApprovedState isApproved={props.isApproved} />} />
                                    <ColumnDirective headerText="Action" width="150" template={(props) => (
                                        <div onClick={() => handleApproveUser({ _id: props._id, isApproved: props.isApproved })} style={{ cursor: "pointer" }}>
                                            {props.isApproved ? (
                                                <ImCross size="15" title="Not Assign" />
                                            ) : (
                                                <FaCheck size="15" title="Assign" />
                                            )}
                                        </div>

                                    )} />

                                </ColumnsDirective>
                                <Inject services={[Toolbar, Search]} />
                            </GridComponent>
                        </div>
                    )
                });
                setIsModalOpen(true);
            } catch (error) {
                console.error("Error fetching contractor data:", error);
            }
        };

        const ApprovedState = ({ isApproved }) => {
            const StatusBg = isApproved ? "green" : "red";

            return (
                <div className="flex gap-2 items-center text-gray-700 capitalize">
                    <div
                        style={{
                            backgroundColor: StatusBg,
                            borderRadius: "50%",
                            width: "12px",
                            height: "12px",
                        }}
                    />
                    <p>{isApproved ? "Assigned" : "Not Assigned"}</p>
                </div>
            );
        };

        const handleStatusChangeClick = () => {
            setSelectedUserId(_id);
            const selectedUser = userData.find((user) => user._id === _id);
            const currentStatus = selectedUser ? selectedUser.status : "";
            setModalContent({
                userId: _id,
                title: "Change Status",
                content: (
                    <div className="w-full p-6 bg-white rounded-lg shadow-md">
                        <h2 className="text-lg font-semibold text-gray-800 mb-4">
                            {modalContent.title}
                        </h2>
                        <Formik
                            initialValues={{ status: currentStatus }}
                            onSubmit={(values) => handleStatusChange(_id, values.status)}
                        >
                            {({ handleChange, handleSubmit, values }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="status"
                                            className="block text-sm font-medium text-gray-700 mb-2"
                                        >
                                            Status
                                        </label>
                                        <Field
                                            as="select"
                                            name="status"
                                            value={values.status}
                                            onChange={handleChange}
                                            className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="">Select Status</option>
                                            <option value="PENDING">Pending</option>
                                            <option value="IN-TOUCH">In Touch</option>
                                            <option value="CLOSED">Closed</option>
                                        </Field>
                                    </div>
                                    <div className="flex justify-center mt-3">
                                        <button
                                            type="submit"
                                            className="bg-gray-800 text-white px-6 py-3 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-300 ease-in-out"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ),
            });
            setIsModalOpen(true);
        };

        return (
            <div className="flex items-center gap-4">
                <button onClick={handleStatusChangeClick}>
                    <SiStatuspal size="20" title="Status Update" />
                </button>
                <button onClick={handleOpenModal}>
                    <HiOutlineEye size="20" title="View Contractors" />
                </button>
            </div>
        );
    };

    const LocationTemplate = ({ address }) => (
        <div>
            {/* <p>{address?.street}</p>
            <p>{address?.city}</p>
            <p>
                {address?.state}, {address?.pincode}
            </p> */}
            <p>{address}</p>
        </div>
    );

    const StatusBadge = ({ status }) => {
        let backgroundColor, text;
        switch (status) {
            case "PENDING":
                backgroundColor = "bg-yellow-500";
                text = "Pending";
                break;
            case "IN-TOUCH":
                backgroundColor = "bg-cyan-500";
                text = "In Touch";
                break;
            case "CLOSED":
                backgroundColor = "bg-green-500";
                text = "Closed";
                break;
            default:
                backgroundColor = "bg-gray-600";
                text = "Unknown";
                break;
        }

        return (
            <div
                className={`text-white py-2 px-2 text-center capitalize rounded-full text-sm ${backgroundColor}`}
                style={{ display: "inline-block", maxWidth: "fit-content" }}
            >
                {text}
            </div>
        );
    };

    const toolbarClick = (args) => {
        if (gridInstance.current && args.item.id === "gridcomp_pdfexport") {
            gridInstance.current.pdfExport();
        }
    };

    const loadingIndicator = { indicatorType: "Shimmer" }; // Loading indicator configuration

    const gridTemplate = ({ fullName, phone, address, jobDescription, startDate, endDate, createdAt, workType, approvedContractor }) => {

        return (<table className="detailtable" width="100%">
            <colgroup>
                <col style={{ width: "45%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "50%" }} />
            </colgroup>
            <tbody>
                <tr>
                    <td>
                        <span style={{ fontWeight: "500" }}>Name:</span>
                        {fullName}
                    </td>
                    <td>
                        <span style={{ fontWeight: "500" }}> Location:</span>
                        <LocationTemplate address={address} />
                    </td>

                    <td>
                        <span style={{ fontWeight: "500" }}> Mobile:</span>
                        {phone}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span style={{ fontWeight: "500" }}>Description:</span>
                        {jobDescription}
                    </td>
                    <td>
                        <span style={{ fontWeight: "500" }}> Created:</span>
                        {createdAt}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span style={{ fontWeight: "500" }}>Start Date:</span>
                        {startDate}
                    </td>
                    <td>
                        <span style={{ fontWeight: "500" }}>End Date:</span>
                        {endDate}
                    </td>
                    <td>
                        <span style={{ fontWeight: "500" }}>Work type:</span>
                        {workType}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span style={{ fontWeight: "500" }}>Assigned Contractor:</span>
                        {approvedContractor}
                    </td>
                </tr>
            </tbody>
        </table>);
    };

    return (
        <div className="mt-12 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
            <div className="flex justify-between items-center mb-4">
                <Header category="Page" title="Contractors Enquiry Form" />
            </div>
            <GridComponent
                id="gridcomp"
                dataSource={userData}
                allowPaging
                allowSorting
                allowFiltering
                toolbar={["Search", "PdfExport"]}
                toolbarClick={toolbarClick}
                width="auto"
                loadingIndicator={loadingIndicator}
                allowPdfExport={true}
                ref={gridInstance}
                detailTemplate={gridTemplate}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field="_id"
                        headerText="ID"
                        isPrimaryKey={true}
                        visible={false}
                    />

                    <ColumnDirective field="fullName" headerText="Name" width="100" />
                    <ColumnDirective
                        field="phone"
                        headerText="Mobile Number"
                        width="120"
                    />

                    <ColumnDirective
                        field="workType"
                        headerText="Job Type"
                        width="120"
                    />

                    {/* <ColumnDirective
                        field="description"
                        headerText="Description"
                        width="100"
                    /> */}

                    <ColumnDirective
                        field="startTime"
                        headerText="Start time"
                        width="100"
                    />

                    <ColumnDirective
                        field="expectedEndTime"
                        headerText="End time"
                        width="100"
                    />

                    {/* <ColumnDirective
                        field="workersRequired"
                        headerText="Workers"
                        width="100"
                   /> */}

                    <ColumnDirective
                        field="status"
                        headerText="Work Status"
                        width="120"
                        allowFiltering={true}
                        template={({ status }) => <StatusBadge status={status} />}
                    />

                    <ColumnDirective
                        headerText="Actions"
                        template={(props) => (
                            <ActionButtonsTemplate {...props} userContractors={props.userContractors}></ActionButtonsTemplate>
                        )}
                        width="100"
                    />
                </ColumnsDirective>
                <Inject
                    services={[
                        Page,
                        Search,
                        Toolbar,
                        Filter,
                        Sort,
                        PdfExport,
                        ExcelExport,
                        DetailRow
                    ]}
                />
            </GridComponent>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
            >
                {modalContent.content}
            </Modal>
        </div>
    );
};

export default Contractor;