import axios from "axios";
import { useState, useEffect } from "react";
import { BASE_URL } from "../api/apiConfig";
import ImageCard from "../components/ImageCard";
import apiClient from "../api/apiClient";
export default function WorkerAppScreen() {
  const [images, setImages] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  useEffect(() => {
    fetchImages();
  }, []);
  const fetchImages = () => {
    axios
      .get(`${BASE_URL}/app/images/worker`)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(`${BASE_URL}/app/upload/worker`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("File uploaded successfully");
        setSelectedFile(null); // Clear the selected file
        fetchImages(); // Refresh the image list
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleDelete = async (imageName) => {
    try {
      console.log("deleting called");
      const response = await apiClient.delete(
        `${BASE_URL}/app/delete/worker/${imageName}`
      );
      const { filename } = response.data;
      setImages((prev) => prev.filter((img) => img.name !== filename));
      console.log(response, "😋😊");
    } catch (error) {
      console.log("Error in deleting image", error);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-2xl font-bold text-center my-4">
        Worker's App Section Images
      </h1>
      <div className="flex justify-center items-center">
        <input
          type="file"
          onChange={handleFileChange}
          className="border-2 border-gray-400 p-2 rounded mr-2"
        />
        <button
          onClick={handleUpload}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Upload
        </button>
      </div>
      <div className="flex flex-wrap justify-center mt-4">
        {images.map((image) => (
          <ImageCard
            key={image.name}
            image={image}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}
