import React, { useState, useEffect } from "react";
import { Button } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { BASE_URL } from "../api/apiConfig";
import BarGraph from "./BarGraph";
import PieChart from "./PieChart";
import MapStats from "../components/MapStats";
import GenderPieChart from "../components/GenderPieChart";
import MonthlyGrowthChart from "../components/MonthlyGrowthChart";
const Dashboard = () => {
  const { currentColor } = useStateContext();
  const [dashboardStats, setDashboardStats] = useState(null);

  const dashboardData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/dashboard`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setDashboardStats(data);
      console.log("data", data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    dashboardData();
  }, []);

  return (
    <>
      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-9">
        {/* First Card - Earnings */}
        <div className="bg-gradient-to-br from-blue-400 to-purple-600 dark:text-gray-200 dark:bg-secondary-dark-bg h-auto rounded-xl p-8 pt-9 bg-no-repeat bg-cover bg-center shadow-lg">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-body text-brown-800">Earnings</p>
              <p className="text-2xl">₹63,448</p>
            </div>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text="Download"
              borderRadius="10px"
              size="md"
            />
          </div>
        </div>
        {dashboardStats && (
          <>
            {/* Second Card - Total Workers */}
            <div className="bg-white dark:text-gray-800 dark:bg-secondary-dark-bg p-4 rounded-2xl flex justify-between items-center shadow-md">
              <div>
                <p className="font-semibold text-xl">Total Workers</p>
                <p className="text-gray-600 text-2xl">
                  {dashboardStats.workerCount}
                </p>
              </div>
            </div>

            {/* Third Card - Total Contractors */}
            <div className="bg-white dark:text-gray-800 dark:bg-secondary-dark-bg p-4 rounded-2xl flex justify-between items-center shadow-md">
              <div>
                <p className="font-semibold text-xl">Total Contractors</p>
                <p className="text-gray-600 text-2xl">
                  {dashboardStats.contractorCount}
                </p>
              </div>
            </div>

            {/* Fourth Card - Total Employees */}
            <div className="bg-white dark:text-gray-800 dark:bg-secondary-dark-bg p-4 rounded-2xl flex justify-between items-center shadow-md">
              <div>
                <p className="font-semibold text-xl">Total Employees</p>
                <p className="text-gray-600 text-2xl">
                  {dashboardStats.employerCount}
                </p>
              </div>
            </div>

            {/* Fifth Card - Total Jobs Posted */}
            <div className="bg-white dark:text-gray-800 dark:bg-secondary-dark-bg p-4 rounded-2xl flex justify-between items-center shadow-md">
              <div>
                <p className="font-semibold text-xl">Total Jobs Posted</p>
                <p className="text-gray-600 text-2xl">
                  {dashboardStats.totalJobs}
                </p>
              </div>
            </div>

            {/* Sixth Card - Ongoing Jobs */}
            <div className="bg-white dark:text-gray-800 dark:bg-secondary-dark-bg p-4 rounded-2xl flex justify-between items-center shadow-md">
              <div>
                <p className="font-semibold text-xl">Ongoing Jobs</p>
                <p className="text-gray-600 text-2xl">
                  {dashboardStats.assignedJobsCount}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div class="mb-4 md:mb-0 border-2 ">
          <MapStats />
        </div>
        <div class="mb-4 md:mb-0 border-2 flex justify-center items-center">
          <GenderPieChart />
        </div>
        <div class="mb-4 md:mb-0 border-2 flex justify-center items-center">
          <BarGraph />
        </div>
        <div class="mb-4 md:mb-0 border-2 flex justify-center items-center">
          <MonthlyGrowthChart />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
