import React, { useState, useEffect, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Search,
  Sort,
  PdfExport,
  ExcelExport,
  Inject,
  Toolbar,
  Filter,
  DetailRow
} from "@syncfusion/ej2-react-grids";
import { Header } from "../components";
import { AiOutlineEdit } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { MdBlock } from "react-icons/md";
import { ImCross } from "react-icons/im";
import { CgUnblock } from "react-icons/cg";
import { MdDelete } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { BASE_URL } from "../api/apiConfig";
import Modal from "../components/Modal";
const UserEmployer = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  let gridInstance = useRef(null);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/all-employerdata`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setUserData(data.employers);
      setLoading(false);
      console.log("Fetched user data:", data.employers);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  const handleBlockUser = async ({ _id, isBlocked }) => {
    try {
      const response = await fetch(`${BASE_URL}/user/update/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isBlocked: !isBlocked }), // Toggle the isBlocked value
      });
      if (!response.ok) {
        throw new Error("Failed to update user");
      }
      // Refresh user data after successful update
      fetchUserData();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const handleApproveUser = async ({ _id, isApproved }) => {
    try {
      const response = await fetch(`${BASE_URL}/user/update/${_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isApproved: !isApproved }), // Toggle the isApproved value
      });
      if (!response.ok) {
        throw new Error("Failed to update user");
      }
      // Refresh user data after successful update
      fetchUserData();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDeleteUser = async (_id) => {
    try {
      const response = await fetch(`${BASE_URL}/user/delete/${_id}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete user");
      }
      // Refresh user data after successful deletion
      fetchUserData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const ActionButtonsTemplate = ({ _id, isBlocked, isApproved }) => {
    return (
      <div className="flex items-center gap-4">
        <NavLink to={`/edit-user/${_id}`}>
          <AiOutlineEdit size="20" />
        </NavLink>
        <div
          onClick={() => handleBlockUser({ _id, isBlocked })}
          style={{ cursor: "pointer" }}
        >
          {isBlocked ? (
            <MdBlock size="20" title="Unblock" color="red" />
          ) : (
            <CgUnblock size="20" title="Block" />
          )}
        </div>
        <div
          onClick={() => handleApproveUser({ _id, isApproved })}
          style={{ cursor: "pointer" }}
        >
          {isApproved ? (
            <ImCross size="15" title="Unapprove" />
          ) : (
            <FaCheck size="15" title="Approve" />
          )}
        </div>
        <div
          onClick={() => handleDeleteUser(_id)}
          style={{ cursor: "pointer", color:"red"}}
        >
          <MdDeleteOutline size="20" title="Delete" />
        </div>
      </div>
    );
  };

  const UserRoleButton = ({ userRole }) => {
    let backgroundColor;
    switch (userRole) {
      case "employer":
        backgroundColor = "#e91e64";
        break;
      case "worker":
        backgroundColor = "#673AB8";
        break;
      case "contractor":
        backgroundColor = "#02897B";
        break;
      default:
        backgroundColor = "#6c757d";
        break;
    }

    return (
      <button
        type="button"
        style={{ background: backgroundColor }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      >
        {userRole}
      </button>
    );
  };
  const AddressTemplate = ({ address }) => (
    <div>
      <p>{address?.street}</p>
      <p>{address?.city}</p>
      <p>
        {address?.state}, {address?.pincode}
      </p>
    </div>
  );
  const MobileVerificationState = ({ isMobileVerified }) => {
    const StatusBg = isMobileVerified ? "green" : "red";

    return (
      <div className="flex gap-2 items-center text-gray-700 capitalize">
        <div
          style={{
            backgroundColor: StatusBg,
            borderRadius: "50%",
            width: "12px",
            height: "12px",
          }}
        />
        <p>{isMobileVerified ? "Verified" : "Not Verified"}</p>
      </div>
    );
  };
  const toolbarClick = (args) => {
    if (gridInstance.current && args.item.id === "gridcomp_pdfexport") {
      gridInstance.current.pdfExport();
    }
  };
  const ApprovedState = ({ isApproved }) => {
    const StatusBg = isApproved ? "green" : "red";

    return (
      <div className="flex gap-2 items-center text-gray-700 capitalize">
        <div
          style={{
            backgroundColor: StatusBg,
            borderRadius: "50%",
            width: "12px",
            height: "12px",
          }}
        />
        <p>{isApproved ? "Approved" : "Not Approved"}</p>
      </div>
    );
  };
  const loadingIndicator = { indicatorType: "Shimmer" }; 

  const gridTemplate = ({ photo, name, laborCard, aadharCard }) => {

    const handleImageClick = (imageUrl) => {
      setSelectedImage(imageUrl);
    };

    const closeModal = () => {
      setSelectedImage(null);
    };

    const pic = photo;
    const adharCard = aadharCard;
    const labourCard = laborCard;

    return (
      <div>
        <table className="detailtable" width="100%">
          <colgroup>
            <col style={{ width: "45%" }} />
            <col style={{ width: "45%" }} />
            <col style={{ width: "50%" }} />
          </colgroup>
          <tbody>
            <tr>
              <td rowSpan={4} style={{ textAlign: "center" }} className="photo">
                <img
                  src={pic}
                  alt="user"
                  className="w-20 h-20 rounded-full"
                  onClick={() => handleImageClick(pic)}
                />
              </td>
              <td>
                <span style={{ fontWeight: "500" }}> First Name:</span>
                {name}
              </td>
            </tr>
            <tr>
              {/* <td rowSpan={4} style={{ textAlign: "center" }} className="photo">
                <img
                  src={adharCard}
                  alt="aadhar"
                  className="w-20 h-20 rounded-full"
                  onClick={() => handleImageClick(adharCard)}
                />
              </td> */}

              <td>
                <a href={aadharCard} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "500" ,color:"green"}}>View Aadhar Card</a>
              </td>
            </tr>
            <tr>
          

              <td>
              <a href={labourCard} target="_blank" rel="noopener noreferrer" style={{ fontWeight: "500", color:"blue"}}>View Labour Card</a>
            </td>
            </tr>
          </tbody>
        </table>

        <Modal isOpen={selectedImage !== null} onClose={closeModal}>
          {selectedImage && (
            <img src={selectedImage} alt="enlarged" />
          )}
        </Modal>
      </div>
    );
  };


  return (
    <div className="mt-12 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex justify-between items-center mb-4">
        <Header category="Page" title="Registered Employers" />
      </div>
      <GridComponent
        id="gridcomp"
        dataSource={userData}
        allowPaging
        allowSorting
        allowFiltering
        toolbar={["Search", "PdfExport"]}
        toolbarClick={toolbarClick}
        width="auto"
        loadingIndicator={loadingIndicator}
        allowPdfExport={true}
        ref={gridInstance}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="_id"
            headerText="ID"
            isPrimaryKey={true}
            visible={false}
          />
          <ColumnDirective
            field="userRole"
            headerText="Role"
            template={({ userRole }) => (
              <UserRoleButton userRole={userRole}></UserRoleButton>
            )}
            width="100"
          />
          <ColumnDirective field="name" headerText="Name" width="90" />
          <ColumnDirective
            field="mobileNumber"
            headerText="Mobile Number"
            width="100"
          />
          <ColumnDirective
            field="address"
            headerText="Address"
            allowFiltering={false}
            template={AddressTemplate}
            width="100"
          />
       
          <ColumnDirective
            field="isApproved"
            headerText="Login Approved"
            template={({ isApproved }) => (
              <ApprovedState isApproved={isApproved}></ApprovedState>
            )}
            width="100"
          />
          {/* <ColumnDirective
            field="isMobileVerified"
            headerText="Verified"
            template={({ isMobileVerified }) => (
              <MobileVerificationState
                isMobileVerified={isMobileVerified}
              ></MobileVerificationState>
            )}
            width="100"
          /> */}
          <ColumnDirective
            headerText="Actions"
            template={(props) => (
              <ActionButtonsTemplate {...props}></ActionButtonsTemplate>
            )}
            width="100"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Search,
            Toolbar,
            Filter,
            Sort,
            PdfExport,
            ExcelExport,
            DetailRow
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default UserEmployer;
