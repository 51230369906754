import React, { useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import UserTable from "../components/UserTable";
import axios from "axios";
import { BASE_URL } from "../../src/api/apiConfig";
import { toast } from "react-toastify";
import * as yup from "yup";
import { FormHelperText } from "@mui/material";
import { useEffect } from "react";
import apiClient from "../api/apiClient";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
  userRole: yup.string().required("User role is required"),
});

const ManageAdmins = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const onDeleteUser = async (userId) => {
    try {
      const url = `${BASE_URL}/admin/${userId}`;
      console.log("Delete URL:", url); // Log the URL for debugging
      const response = await apiClient.delete(url);
      if (response.status === 200) {
        toast.success("User Deleted Successfully.");
        setUsers((prev) =>
          prev.filter((user) => user._id !== response.data.user._id)
        );
      }
    } catch (error) {
      console.log("Error while deleting user: ", error);
      toast.error(error.response.data.message);
    }
  };
  const fetchUsers = async () => {
    try {
      const response = await apiClient.get(`${BASE_URL}/admin`);
      if (!response.status === 200) {
        throw new Error("Failed to fetch users");
      }
      console.log(response, "😁😀");
      const data = response.data.users;
      // Extract only email and password fields
      const filteredUsers = data.map(({ _id, email, userRole }) => ({
        _id,
        email,
        userRole,
      }));
      setUsers(filteredUsers);
      console.log(filteredUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userRole: "",
  });

  const [formError, setFormError] = useState("");

  const handleChange = (e) => {
    const name = e.event.target.name;
    const value = e.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoleChange = (e) => {
    setFormData({ ...formData, userRole: e.value });
  };

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });

      if (!isEmailValid(formData.email)) {
        toast.error("Please enter a valid email address.");
        return; // Stop form submission if email is not valid
      }

      const response = await apiClient.post("/admin", formData);
      if (response.status === 201) {
        const { _id, email, userRole } = response.data.user;
        toast.success("User created Successfully!");
        // console.log(response);
        setUsers((prevUsers) => [...prevUsers, { _id, email, userRole }]);
      }
    } catch (error) {
      console.log("Error in adding user: ", error.message);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div className="w-full p-4">
        <h2 className="text-xl font-bold mb-4">Add User</h2>
        <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 w-full">
          <div className="form-group flex-1">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email ID
            </label>
            <TextBoxComponent
              id="email"
              name="email"
              placeholder="Enter email"
              value={formData.email}
              change={handleChange}
              className="mt-1 block w-full"
            />
          </div>
          <div className="form-group flex-1">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <TextBoxComponent
              id="password"
              name="password"
              type="password"
              placeholder="Enter password"
              value={formData.password}
              change={handleChange}
              className="mt-1 block w-full"
            />
          </div>
          <div className="form-group flex-1">
            <label
              htmlFor="userRole"
              className="block text-sm font-medium text-gray-700"
            >
              User Role
            </label>
            <DropDownListComponent
              id="userRole"
              name="userRole"
              dataSource={["telecaller", "admin", "accountant"]}
              value={formData.userRole}
              change={handleRoleChange}
              placeholder="Select role"
              className="mt-1 block w-full"
            />
          </div>
        </div>
        {formError && (
          <div className="form-group mt-2">
            <FormHelperText error>{formError}</FormHelperText>
          </div>
        )}
        <div className="form-group mt-4">
          <ButtonComponent
            cssClass="e-primary"
            className="w-full md:w-auto"
            onClick={handleSubmit}
          >
            Add User
          </ButtonComponent>
        </div>
      </div>

      <div>
        <UserTable users={users} onDeleteUser={onDeleteUser} />
      </div>
    </>
  );
};

export default ManageAdmins;
