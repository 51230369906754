import React, { useState, useEffect } from "react";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Category,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";

import { BASE_URL } from "../api/apiConfig";

const BarGraph = () => {
  const [chartData, setChartData] = useState({
    employerCount: 0,
    totalAppEmployers: 0,
    contractorCount: 0,
    workerCount: 0,
  });

  const SAMPLE_CSS = `
        .control-fluid {
            padding: 0px !important;
        }`;

  const fetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/dashboard`);
      if (!response.ok) {
        throw new Error("Failed to fetch dashboard data");
      }
      const data = await response.json();

      setChartData({
        employerCount: data.employerCount,
        totalAppEmployers: data.totalAppEmployers,
        contractorCount: data.contractorCount,
        workerCount: data.workerCount,
      });
      console.log("dat", data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="control-pane">
      <style>{SAMPLE_CSS}</style>
      <div className="control-section">
        <ChartComponent
          id="charts"
          style={{ textAlign: "center" }}
          primaryXAxis={{
            valueType: "Category",
            title: "Employment Type",
            interval: 1,
          }}
          primaryYAxis={{ title: "Total Number", minimum: 0, maximum: 100 }}
          chartArea={{ border: { width: 0 } }}
          enableSideBySidePlacement={false}
          title="Employment Statistics"
          tooltip={{ enable: true, shared: true }}
          width={Browser.isDevice ? "100%" : "75%"}
        >
          <Inject services={[ColumnSeries, Category, Tooltip, Legend]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={[{ x: "Web Employers", y: chartData.employerCount }]}
              xName="x"
              yName="y"
              name="Web Employers"
              type="Column"
            />
            <SeriesDirective
              dataSource={[
                { x: "App Employers", y: chartData.totalAppEmployers },
              ]}
              xName="x"
              yName="y"
              name="App Employers"
              type="Column"
            />
            <SeriesDirective
              dataSource={[{ x: "Contractors", y: chartData.contractorCount }]}
              xName="x"
              yName="y"
              name="Contractors"
              type="Column"
            />
            <SeriesDirective
              dataSource={[{ x: "Workers", y: chartData.workerCount }]}
              xName="x"
              yName="y"
              name="Workers"
              type="Column"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>
    </div>
  );
};

export default BarGraph;
