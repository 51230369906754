import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageCard from "../components/ImageCard";
import { BASE_URL } from "../api/apiConfig";
import apiClient from "../api/apiClient";
const EmployerImages = () => {
  const [images, setImages] = useState([]);

  const [images2, setImages2] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  useEffect(() => {
    fetchImages();
    fetchImages2();
  }, []);
  const fetchImages2 = () => {
    axios
      .get(`${BASE_URL}/app/images/employer2`)
      .then((response) => {
        setImages2(response.data);
      })
      .catch((error) => {
        console.error("Error fetching section 2 images:", error);
      });
  };

  const fetchImages = () => {
    axios
      .get(`${BASE_URL}/app/images/employer`)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };
  const handleUpload = () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(`${BASE_URL}/app/upload/employer`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("File uploaded successfully");
        setSelectedFile(null); // Clear the selected file
        fetchImages(); // Refresh the image list
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleUpload2 = () => {
    if (!selectedFile2) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile2);

    axios
      .post(`${BASE_URL}/app/upload/employer2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("File uploaded successfully");
        setSelectedFile2(null); // Clear the selected file
        fetchImages2(); // Refresh the image list
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  const handleDelete = async (imageName) => {
    try {
      console.log("deleting called");
      const response = await apiClient.delete(
        `${BASE_URL}/app/delete/employer/${imageName}`
      );
      const { filename } = response.data;
      setImages((prev) => prev.filter((img) => img.name !== filename));
      console.log(response, "😋😊");
    } catch (error) {
      console.log("Error in deleting image", error);
    }
  };

  const handleDelete2 = async (imageName) => {
    try {
      console.log("deleting called");
      const response = await apiClient.delete(
        `${BASE_URL}/app/delete/employer2/${imageName}`
      );
      const { filename } = response.data;
      setImages2((prev) => prev.filter((img) => img.name !== filename));
      console.log(response, "😋😊");
    } catch (error) {
      console.log("Error in deleting image", error);
    }
  };
  return (
    <>
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold text-center my-4">Section 1</h1>
        <div className="flex justify-center items-center">
          <input
            type="file"
            onChange={handleFileChange}
            className="border-2 border-gray-400 p-2 rounded mr-2"
          />
          <button
            onClick={handleUpload}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Upload
          </button>
        </div>
        <div className="flex flex-wrap justify-center mt-4">
          {images.map((image, index) => (
            <ImageCard
              key={image.name}
              image={image}
              setImages={setImages}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      </div>

      <div className="container mx-auto">
        <h1 className="text-2xl font-bold text-center my-4">Section 2</h1>
        <div className="flex justify-center items-center">
          <input
            type="file"
            onChange={handleFileChange2}
            className="border-2 border-gray-400 p-2 rounded mr-2"
          />
          <button
            onClick={handleUpload2}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Upload
          </button>
        </div>
        <div className="flex flex-wrap justify-center mt-4">
          {images2.map((image, index) => (
            <ImageCard
              key={image.name}
              image={image}
              handleDelete={handleDelete2}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default EmployerImages;
