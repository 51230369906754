// import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { MdOutlineCancel } from "react-icons/md";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// import { links } from "../data/dummy";
// import { useStateContext } from "../contexts/ContextProvider";
// import MRMA from "../data/mp.png";
// const Sidebar = () => {
//   const { activeMenu, setActiveMenu, screenSize, currentColor } =
//     useStateContext();

//   const handleCloseSidebar = () => {
//     if (activeMenu && screenSize <= 900) {
//       setActiveMenu(false);
//     }
//   };

//   const activeLink =
//     "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
//   const normalLink =
//     "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

//   return (
//     <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
//       {activeMenu && (
//         <>
//           <div className="flex justify-between items-center">
//             <Link
//               to="/"
//               onClick={handleCloseSidebar}
//               className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
//             >
//               <img alt="Logo" src={MRMA} className="w-14 h-14 " />
//               <span>MRMA</span>
//             </Link>
//             <TooltipComponent content="Menu" position="BottomCenter">
//               <button
//                 type="button"
//                 onClick={() =>
//                   setActiveMenu((prevActiveMenu) => !prevActiveMenu)
//                 }
//                 className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block"
//               >
//                 <MdOutlineCancel />
//               </button>
//             </TooltipComponent>
//           </div>
//           <div className="m-10">
//             {links.map((item) => (
//               <div key={item.title}>
//                 <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>
//                 {item.links.map((link) => (
//                   <NavLink
//                     to={`/${link.name}`}
//                     key={link.name}
//                     onClick={handleCloseSidebar}
//                     style={({ isActive }) => ({
//                       backgroundColor: isActive ? currentColor : "",
//                     })}
//                     className={({ isActive }) =>
//                       isActive ? activeLink : normalLink
//                     }
//                   >
//                     {link.icon}
//                     <span className="capitalize">{link.name}</span>
//                   </NavLink>
//                 ))}
//               </div>
//             ))}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Sidebar;

// import React, { useState } from "react";
// import { Link, NavLink } from "react-router-dom";
// import { MdOutlineCancel } from "react-icons/md";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// import { links } from "../data/dummy";
// import { useStateContext } from "../contexts/ContextProvider";
// import MRMA from "../data/mp.png";

// const Sidebar = () => {
//   const { activeMenu, setActiveMenu, screenSize, currentColor } =
//     useStateContext();
//   const [expanded, setExpanded] = useState(false); // State to manage expansion

//   const handleCloseSidebar = () => {
//     if (activeMenu && screenSize <= 900) {
//       setActiveMenu(false);
//     }
//   };

//   const toggleExpansion = () => {
//     setExpanded((prevExpanded) => !prevExpanded);
//   };

//   const activeLink =
//     "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
//   const normalLink =
//     "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

//   return (
//     <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
//       {activeMenu && (
//         <>
//           <div className="flex justify-between items-center">
//             <Link
//               to="/"
//               onClick={handleCloseSidebar}
//               className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
//             >
//               <img alt="Logo" src={MRMA} className="w-14 h-14 " />
//               <span>MRMA</span>
//             </Link>
//             <TooltipComponent content="Menu" position="BottomCenter">
//               <button
//                 type="button"
//                 onClick={() =>
//                   setActiveMenu((prevActiveMenu) => !prevActiveMenu)
//                 }
//                 className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block"
//               >
//                 <MdOutlineCancel />
//               </button>
//             </TooltipComponent>
//           </div>
//           <div className="m-10">
//             {links.map((item) => (
//               <div key={item.title}>
//                 <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>

//                 {item.links.map((link) => (
//                   <React.Fragment key={link.name}>
//                     {link.name === "employers" ? (
//                       <div>
//                         <div
//                           className="flex justify-between items-center"
//                           onClick={toggleExpansion}
//                         >
//                           <NavLink
//                             to="#" // Set "#" as the href to make it non-clickable
//                             className={normalLink}

//                           >
//                             {link.icon}
//                             <span className="capitalize">{link.name}</span>
//                             {link.expandIcon}
//                           </NavLink>
//                         </div>
//                         {expanded && (
//                           <div>
//                             {/* Render additional options when expanded */}
//                             <NavLink
//                               to="/contractor"
//                               onClick={handleCloseSidebar}
//                               className={normalLink}
//                               style={({ isActive }) => ({
//                                 backgroundColor: isActive ? currentColor : "",
//                               })}
//                             >
//                               Contractor
//                             </NavLink>
//                             <NavLink
//                               to="/labour"
//                               onClick={handleCloseSidebar}
//                               className={normalLink}
//                               style={({ isActive }) => ({
//                                 backgroundColor: isActive ? currentColor : "",
//                               })}
//                             >
//                               Labour
//                             </NavLink>
//                           </div>
//                         )}
//                       </div>
//                     ) : (
//                       <NavLink
//                         to={`/${link.name}`}
//                         key={link.name}
//                         onClick={handleCloseSidebar}
//                         style={({ isActive }) => ({
//                           backgroundColor: isActive ? currentColor : "",
//                         })}
//                         className={({ isActive }) => (isActive ? activeLink : normalLink)}
//                       >
//                         {link.icon}
//                         <span className="capitalize">{link.name}</span>
//                       </NavLink>
//                     )}
//                   </React.Fragment>
//                 ))}

//               </div>
//             ))}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Sidebar;

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../contexts/ContextProvider";
import { links } from "../data/dummy";
import MRMA from "../data/mp.png";
import { useUserData } from "../contexts/AuthContext";
const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor } =
    useStateContext();
  const [userExpanded, setUserExpanded] = useState(false);
  const [employersExpanded, setEmployersExpanded] = useState(false);

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const toggleUserExpansion = () => {
    setUserExpanded((prevExpanded) => !prevExpanded);
  };

  const toggleEmployersExpansion = () => {
    setEmployersExpanded((prevExpanded) => !prevExpanded);
  };

  const activeLink =
    "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink =
    "flex item-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  const { userRole } = useUserData();

  const filteredLinks = links.map((section) => ({
    ...section,
    links: section.links.filter((link) =>
      link.visibleToRoles ? link.visibleToRoles.includes(userRole) : true
    ),
  }));
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSidebar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img alt="Logo" src={MRMA} className="w-14 h-14 " />
              <span>MRMA</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="m-10">
            {filteredLinks.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 m-3 mt-4 uppercase">{item.title}</p>

                {item.links.map((link) => (
                  <React.Fragment key={link.name}>
                    {link.name === "user" && (
                      <div>
                        <div
                          className="flex justify-between items-center"
                          onClick={toggleUserExpansion}
                        >
                          <NavLink to="#" className={normalLink}>
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                            {link.expandIcon}
                          </NavLink>
                        </div>
                        {userExpanded && (
                          <div>
                            {/* Render additional options when expanded */}
                            <NavLink
                              to="/user-employer"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Employers
                            </NavLink>
                            <NavLink
                              to="/user-contractor"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Contractors
                            </NavLink>
                            <NavLink
                              to="/user-worker"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Workers
                            </NavLink>
                            <NavLink
                              to="/contractor-worker"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Contractor Workers
                            </NavLink>
                            <NavLink
                              to="/app-employer"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              App Employers
                            </NavLink>
                          </div>
                        )}
                      </div>
                    )}
                    {link.name === "employer requests" && (
                      <div>
                        <div
                          className="flex justify-between items-center"
                          onClick={toggleEmployersExpansion}
                        >
                          <NavLink to="#" className={normalLink}>
                            {link.icon}
                            <span className="capitalize">{link.name}</span>
                            {link.expandIcon}
                          </NavLink>
                        </div>
                        {employersExpanded && (
                          <div>
                            {/* Render additional options when expanded */}
                            <NavLink
                              to="/contractor"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Contractor Enquiry
                            </NavLink>
                            <NavLink
                              to="/labour"
                              onClick={handleCloseSidebar}
                              className={normalLink}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                            >
                              Labour Enquiry
                            </NavLink>
                          </div>
                        )}
                      </div>
                    )}
                    {link.name !== "user" &&
                      link.name !== "employer requests" && (
                        <NavLink
                          to={`/${link.name}`}
                          key={link.name}
                          onClick={handleCloseSidebar}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : "",
                          })}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          {link.icon}
                          <span className="capitalize">{link.name}</span>
                        </NavLink>
                      )}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
