import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { UserDataProvider } from "./contexts/AuthContext";
ReactDOM.render(
  <UserDataProvider>
    <ContextProvider>
      <App />
    </ContextProvider>
  </UserDataProvider>,
  document.getElementById("root")
);
