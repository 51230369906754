import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { IoMdContacts } from "react-icons/io";
import { RiContactsLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { MdExpandMore } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { RiMailSendFill } from "react-icons/ri";
import { BsBank } from "react-icons/bs";
import { RiAdminFill } from "react-icons/ri";
import { IoMdPhotos } from "react-icons/io";
export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "Dashboard",
        icon: <FiShoppingBag />,
      },
    ],
  },
  {
    title: "Pages",
    links: [
      {
        name: "add-user",
        icon: <AiOutlineUsergroupAdd />,
        visibleToRoles: ["admin"], // Visible to admins
      },
      {
        name: "user",
        icon: <IoMdContacts />,
        expandIcon: <MdExpandMore />,
        visibleToRoles: ["admin", "accountant"], // Visible to admins
      },
      {
        name: "lead",
        icon: <RiContactsLine />,
        visibleToRoles: ["admin", "telecaller"], // Visible to admins
      },
      {
        name: "bank-account",
        icon: <BsBank />,
        visibleToRoles: ["admin", "accountant"], // Visible to admins and accountants
      },
      {
        name: "employer requests",
        icon: <FiUsers />,
        expandIcon: <MdExpandMore />,
        visibleToRoles: ["admin", "telecaller"], // Visible to admins
      },
      {
        name: "Mailbox",
        icon: <RiMailSendFill />,
        visibleToRoles: ["admin", "telecaller"], // Visible to telecallers
      },
      {
        name: "Manage-admins",
        icon: <RiAdminFill />,
        visibleToRoles: ["admin"], // Visible to admins
      },
      {
        name: "employer-app-screen",
        icon: <IoMdPhotos />,
        visibleToRoles: ["admin"], // Visible to admins
      },
      {
        name: "worker-app-screen",
        icon: <IoMdPhotos />,
        visibleToRoles: ["admin"],
      },
    ],
  },
];

// Define user roles somewhere in your application context or state

// {
//   title: 'Apps',
//   links: [
//     {
//       name: 'calendar',
//       icon: <AiOutlineCalendar />,
//     },
//     {
//       name: 'kanban',
//       icon: <BsKanban />,
//     },
//     {
//       name: 'editor',
//       icon: <FiEdit />,
//     },
//     {
//       name: 'color-picker',
//       icon: <BiColorFill />,
//     },
//   ],
// },
// {
//   title: 'Charts',
//   links: [
//     {
//       name: 'line',
//       icon: <AiOutlineStock />,
//     },
//     {
//       name: 'area',
//       icon: <AiOutlineAreaChart />,
//     },

//     {
//       name: 'bar',
//       icon: <AiOutlineBarChart />,
//     },
//     // {
//     //   name: 'pie',
//     //   icon: <FiPieChart />,
//     // },
//     // {
//     //   name: 'financial',
//     //   icon: <RiStockLine />,
//     // },
//     // {
//     //   name: 'color-mapping',
//     //   icon: <BsBarChart />,
//     // },
//     // {
//     //   name: 'pyramid',
//     //   icon: <GiLouvrePyramid />,
//     // },
//     // {
//     //   name: 'stacked',
//     //   icon: <AiOutlineBarChart />,
//     // },
//   ],
// },

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];
