import React, { useState, useEffect, useRef } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Search,
  Sort,
  PdfExport,
  ExcelExport,
  Inject,
  Toolbar,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { Header } from "../components";
import Modal from "../components/Modal";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import { SiStatuspal } from "react-icons/si";
import { FaComment } from "react-icons/fa";
import {
  fetchAllUserData,
  handleStatusToChange,
  handleCommentUpdate,
} from "../api/LeadUser";
import { BASE_URL } from "../api/apiConfig";
const LeadUser = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const gridInstance = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [comments, setComments] = useState([]);
  const fetchUserData = async () => {
    try {
      const data = await fetchAllUserData();
      setUserData(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);
  const fetchUserComments = async (userId) => {
    try {
      // Make an API request to fetch comments for the user ID
      const response = await fetch(`${BASE_URL}/user/user-data/${userId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch user comments");
      }
      const data = await response.json();
      console.log(data.user.comments);
      setComments(data.user.comments);
    } catch (error) {
      console.error("Error fetching user comments:", error);
      alert("Failed to fetch comments. Please try again later.");
    }
  };
  useEffect(() => {
    if (!isModalOpen) {
      setComments([]); // Clear comments when modal is closed
    }
  }, [isModalOpen]);

  // Or, reset comments when selectedUserId changes
  useEffect(() => {
    setComments([]);
  }, [selectedUserId]);

  // Handler function for "Show Comment" button click
  const handleShowCommentClick = async (_id) => {
    // Call the fetchUserComments function with the selected user ID
    setComments([]);
    await fetchUserComments(_id);
  };
  const handleStatusChange = async (userId, updatedStatus) => {
    try {
      await handleStatusToChange(userId, updatedStatus);
      const updatedUserData = userData.map((user) =>
        user._id === userId ? { ...user, status: updatedStatus } : user
      );
      setUserData(updatedUserData);
      setIsModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Status Updated",
      });
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status. Please try again later.");
    }
  };

  const handleCommentSubmit = async (_id, comment) => {
    try {
      await handleCommentUpdate(_id, comment);
      await fetchUserComments(_id);
      fetchUserData();
      setIsModalOpen(false);
      Swal.fire({
        icon: "success",
        title: "Comment Added",
      });
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again later.");
    }
  };
  useEffect(() => {
    if (isModalOpen === false && selectedUserId) {
      fetchUserData();
      setSelectedUserId(null); // Reset selected user ID after refetch
    }
  }, [isModalOpen]);
  const ActionButtonsTemplate = ({ _id }) => {
    const handleStatusChangeClick = () => {
      setSelectedUserId(_id);
      const selectedUser = userData.find((user) => user._id === _id);
      const currentStatus = selectedUser ? selectedUser.status : "";
      setModalContent({
        userId: _id,
        title: "Change Status",
        content: (
          <div className="w-full p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              {modalContent.title}
            </h2>
            <Formik
              initialValues={{ status: currentStatus }}
              onSubmit={(values) => handleStatusChange(_id, values.status)}
            >
              {({ handleChange, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="status"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Status
                    </label>
                    <Field
                      as="select"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    >
                      <option value="">Select Status</option>
                      <option value="PENDING">Pending</option>
                      <option value="IN-TOUCH">In Touch</option>
                      <option value="CLOSED">Closed</option>
                    </Field>
                  </div>
                  <div className="flex justify-center mt-3">
                    <button
                      type="submit"
                      className="bg-gray-800 text-white px-6 py-3 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-300 ease-in-out"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ),
      });
      setIsModalOpen(true);
    };

    const handleCommentClick = () => {
      setSelectedUserId(_id);
      console.log(_id);
      setModalContent({
        title: "Add Comment",
        content: (
          <div className="w-full p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Add Comment
            </h2>
            <Formik
              initialValues={{ comment: "" }}
              onSubmit={(values) => handleCommentSubmit(_id, values.comment)}
            >
              {({ handleChange, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      Comment
                    </label>
                    <Field
                      as="textarea"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      className="w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="flex justify-center mt-3">
                    <button
                      type="submit"
                      className="bg-gray-800 text-white px-6 py-3 rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700 transition duration-300 ease-in-out"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <button onClick={() => handleShowCommentClick(_id)}>
              Show Comments
            </button>
          </div>
        ),
      });
      setIsModalOpen(true);
    };

    return (
      <div className="flex items-center gap-4">
        <button onClick={handleStatusChangeClick}>
          <SiStatuspal size="20" title="Status Update" />
        </button>
        <button onClick={handleCommentClick}>
          <FaComment size="20" title="Add Comment" />
        </button>
      </div>
    );
  };

  const UserRoleButton = ({ userRole }) => {
    const lowercaseUserRole = userRole.toLowerCase();
    let backgroundColor;
    switch (lowercaseUserRole) {
      case "employer":
        backgroundColor = "#e91e64";
        break;
      case "worker":
        backgroundColor = "#673AB8";
        break;
      case "contractor":
        backgroundColor = "#02897B";
        break;
      default:
        backgroundColor = "#6c757d";
        break;
    }

    return (
      <button
        type="button"
        style={{ background: backgroundColor }}
        className="text-white py-1 px-2 capitalize rounded-2xl text-md"
      >
        {userRole}
      </button>
    );
  };

  const toolbarClick = (args) => {
    if (gridInstance.current && args.item.id === "gridcomp_pdfexport") {
      gridInstance.current.pdfExport();
    }
  };

  const StatusBadge = ({ status }) => {
    let backgroundColor, text;
    switch (status) {
      case "PENDING":
        backgroundColor = "bg-yellow-500";
        text = "Pending";
        break;
      case "IN-TOUCH":
        backgroundColor = "bg-cyan-500";
        text = "In Touch";
        break;
      case "CLOSED":
        backgroundColor = "bg-green-500";
        text = "Closed";
        break;
      default:
        backgroundColor = "bg-gray-600";
        text = "Unknown";
        break;
    }

    return (
      <div
        className={`text-white py-2 px-2 text-center capitalize rounded-full text-sm ${backgroundColor}`}
        style={{ display: "inline-block", maxWidth: "fit-content" }}
      >
        {text}
      </div>
    );
  };

  const loadingIndicator = { indicatorType: "Shimmer" }; // Loading indicator configuration

  return (
    <div className="m-12 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Lead Users" />
      <GridComponent
        id="gridcomp"
        dataSource={userData}
        allowPaging
        allowSorting
        allowFiltering
        toolbar={["Search", "PdfExport"]}
        toolbarClick={toolbarClick}
        width="auto"
        loadingIndicator={loadingIndicator}
        allowPdfExport={true}
        ref={gridInstance}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="_id"
            headerText="ID"
            isPrimaryKey={true}
            visible={false}
          />
          <ColumnDirective
            field="userRole"
            headerText="Role"
            template={({ userRole }) => (
              <UserRoleButton userRole={userRole}></UserRoleButton>
            )}
            width="100"
          />
          {/* <ColumnDirective field="name" headerText="Name" width="90" /> */}
          <ColumnDirective
            field="name"
            headerText="Name"
            width="90"
            template={({ name, fullName }) => (
              <span>{name || fullName}</span>
            )}
          />
          <ColumnDirective
            field="mobileNumber"
            headerText="Mobile Number"
            width="100"
          />
          <ColumnDirective
            field="address"
            headerText="City"
            allowFiltering={true}
            width="100"
            template={({ address }) => (
              <span>
                {typeof address === 'string' ? address : address.city}
              </span>
            )}
          />
          <ColumnDirective
            field="status"
            headerText="Status"
            width="100"
            allowFiltering={true}
            template={({ status }) => <StatusBadge status={status} />}
          />

          <ColumnDirective
            headerText="Actions"
            template={(props) => (
              <ActionButtonsTemplate {...props}></ActionButtonsTemplate>
            )}
            width="100"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Page,
            Search,
            Toolbar,
            Filter,
            Sort,
            PdfExport,
            ExcelExport,
          ]}
        />
      </GridComponent>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
      >
        {modalContent.content}

        {comments.map((comment, index) => (
          <div key={index} className="overflow-x-auto">
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2">Comment</th>
                  <th className="px-4 py-2">Commented At</th>
                </tr>
              </thead>
              <tbody>
                <tr className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                  <td className="px-4 py-2 text-center">{comment.comment}</td>
                  <td className="px-4 py-2 text-center">
                    {new Date(comment.commentedAt).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default LeadUser;
