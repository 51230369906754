import React, { useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { BASE_URL } from "../api/apiConfig";
import axios from "axios";

const Mailbox = () => {
  const path = {
    removeUrl:
      "https://services.syncfusion.com/react/production/api/FileUploader/Remove",
    saveUrl:
      "https://services.syncfusion.com/react/production/api/FileUploader/Save",
    chunkSize: 102400,
  };

  console.log(path);
  const [userType, setUserType] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [files, setFiles] = useState([]);

  const handleUserTypeChange = (e) => {
    setUserType(e.itemData.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.value);
    // console.log(e);
  };

  const handleBodyChange = (e) => {
    setBody(e.target.value);
  };

  const handleFileChange = (args) => {
    setFiles((prevFiles) => [...prevFiles, args]);
  };

  const handleSubmit = () => {
    // Prepare form data
    const formData = new FormData();
    formData.append("userType", userType);
    formData.append("subject", subject);
    formData.append("body", body);
    console.log("files", files);
    for (const file of files) {
      formData.append("files", file.file.rawFile);
      console.log(file.file.rawFile, "file");
    }

    axios
      .post(`${BASE_URL}/user/sendMails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data.message);
      })
      .catch((error) => {
        console.error("Error sending mail:", error);
        // Handle error
      });
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="text-xl font-bold mb-4">Mailbox</h2>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">User Type</label>
        <DropDownListComponent
          placeholder="Select User Type"
          dataSource={["Contractor", "Employer", "Worker"]}
          change={handleUserTypeChange}
          className="form-input"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Subject</label>
        <TextBoxComponent
          value={subject}
          change={handleSubjectChange}
          className="form-input border-2 border-solid w-full p-1"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Body</label>
        <textarea
          value={body}
          onChange={handleBodyChange}
          placeholder="&nbsp;message"
          className="form-textarea w-full border-2 p-2"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-bold mb-2">Attach Files</label>
        <UploaderComponent
          asyncSettings={path}
          multiple={true}
          autoUpload={true}
          sequentialUpload={true}
          directoryUpload={false}
          allowedExtensions=".pdf,.jpeg,.jpg"
          minFileSize={1}
          maxFileSize={1000000}
          success={handleFileChange}
        ></UploaderComponent>
      </div>
      <div className="flex items-center">
        <ButtonComponent
          onClick={handleSubmit}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:bg-blue-700"
        >
          Send
        </ButtonComponent>
      </div>
    </div>
  );
};

export default Mailbox;
