import React from "react";

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto backdrop-blur-md">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white w-1/2 p-6 rounded-lg">
          <div className="mb-4">
            <h2 className="text-xl font-bold">{title}</h2>
          </div>
          <div className="mb-4">{children}</div>
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="text-blue-500 hover:text-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;