import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { NavBar, Sidebar, ThemeSettings } from "./components";
import { Dashboard } from "./pages";
import { useStateContext } from "./contexts/ContextProvider";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signin from "./pages/Signin";
// import FormComponent from "./pages/FormComponent";
import RegisterForm from "./pages/RegisterForm";
import EditForm from "./pages/EditForm";
import Contractor from "./pages/Contractor";
import Labour from "./pages/Labour";
import LeadUser from "./pages/LeadUser";
import UserEmployer from "./pages/UserEmployer";
import AppEmployer from "./pages/AppEmployer";
import ContractorWorker from "./pages/ContractorWorker";
import UserContractor from "./pages/UserContractor";
import UserWorker from "./pages/UserWorker";
import Mailbox from "./pages/Mailbox";
import Swal from "sweetalert2";
import EmployerEditForm from "./pages/EmployerEditForm";
import UserBank from "./pages/UserBank";
import ManageAdmins from "./pages/ManageAdmins";
import EmployerAppScreen from "./pages/EmployerAppScreen";
import axios from "axios";
import { BASE_URL } from "./api/apiConfig";
import WorkerAppScreen from "./pages/WorkerAppScreen";
import { useUserData } from "./contexts/AuthContext";
import apiClient from "./api/apiClient";
const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
  } = useStateContext();

  const {
    userRole,
    token,
    setToken,
    setUserRole,
    authenticated,
    setAuthenticated,
  } = useUserData();

  const handleAuthentication = async (email, password) => {
    // JSON data containing email and password for authenticated users

    try {
      const res = await apiClient.post(`${BASE_URL}/api/login`, {
        email,
        password,
      });
      if (res.status === 200) {
        setAuthenticated(true);
        setToken(res.data.token);
        setUserRole(res.data.userRole);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userRole", res.data.userRole);
        localStorage.setItem("isAuthenticated", true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Authentication Failed",
          text: "Invalid email or password. Please try again.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Authentication Failed",
        text: "Invalid email or password. Please try again.",
      });
    }

    // const authenticatedUsers = [
    //   {
    //     email: "rightpathpredictor@gmail.com",
    //     password: "Rpp@1234",
    //   },
    //   // Add more authenticated users as needed
    // ];
    // const isAuthenticated = authenticatedUsers.some(
    //   (user) => user.email === email && user.password === password
    // );
    // if (isAuthenticated) {
    //   setAuthenticated(true); // Set authenticated to true if user is authenticated
    //   localStorage.setItem("authenticated", true); // Store authentication state in browser storage
    // } else {
    //   // Show SweetAlert error message
    // }
  };
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="TopCenter">
              <button
                type="button"
                className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                onClick={() => setThemeSettings(true)}
                style={{ background: currentColor, borderRadius: "50%" }}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {authenticated && (
            <>
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
              <div
                className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${
                  activeMenu ? "md:ml-72" : "flex-2"
                }`}
              >
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                  <NavBar />
                </div>
                <div>
                  {themeSettings && <ThemeSettings />}
                  <Routes>
                    {/* Pages */}
                    {/* <Route path="/user" element={<Users />} /> */}
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/user-employer" element={<UserEmployer />} />
                    <Route path="/app-employer" element={<AppEmployer />} />
                    <Route
                      path="/contractor-worker"
                      element={<ContractorWorker />}
                    />
                    <Route
                      path="/user-contractor"
                      element={<UserContractor />}
                    />
                    <Route path="/user-worker" element={<UserWorker />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* <Route path="/add-user" element={<FormComponent />} /> */}
                    <Route path="/add-user" element={<RegisterForm />} />
                    <Route path="/lead" element={<LeadUser />} />
                    <Route path="/bank-account" element={<UserBank />} />
                    <Route path="/contractor" element={<Contractor />} />
                    <Route path="/labour" element={<Labour />} />
                    <Route path="/edit-user/:_id" element={<EditForm />} />
                    <Route
                      path="/edit-emp/:_id"
                      element={<EmployerEditForm />}
                    />
                    <Route path="/mailbox" element={<Mailbox />} />
                    <Route path="/manage-admins" element={<ManageAdmins />} />
                    <Route
                      path="employer-app-screen"
                      element={<EmployerAppScreen />}
                    />
                    <Route
                      path="worker-app-screen"
                      element={<WorkerAppScreen />}
                    />
                  </Routes>
                </div>
              </div>
            </>
          )}
          {!authenticated && (
            <div className="w-full">
              <Routes>
                <Route
                  path="/dashboard"
                  element={<Signin onAuthenticate={handleAuthentication} />} // Show Signin if not authenticated
                />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </div>
          )}
        </div>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default App;
