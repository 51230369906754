import axios from "axios";
import { BASE_URL } from "./apiConfig";
import { useUserData } from "../contexts/AuthContext";
const apiClient = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the token
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // or get the token from wherever you store it
    // const { token } = useUserData();
    // console.log(token, "token======");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
