import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";

const UserTable = ({ users, onDeleteUser }) => {
  // Columns configuration for the grid
  const columns = [
    { field: "email", headerText: "Email" },
    { field: "userRole", headerText: "User Role" },
    {
      field: "actions",
      headerText: "Actions",
      template: (props) => (
        <button
          onClick={() => {
            console.log("delete button clicked", props);
            onDeleteUser(props._id);
          }}
        >
          <MdDeleteForever />
        </button>
      ),
      width: 100,
      textAlign: "center",
    },
  ];

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">User List</h2>
      <GridComponent
        dataSource={users}
        allowPaging={true}
        pageSettings={{ pageSize: 10 }}
      >
        <ColumnsDirective>
          {columns.map((col, index) => (
            <ColumnDirective key={index} {...col} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page]} />
      </GridComponent>
    </div>
  );
};

export default UserTable;
