import React from "react";
import { MdDelete } from "react-icons/md";

const ImageCard = ({ image, handleDelete }) => {
  return (
    <div className="relative m-2">
      <img
        className="object-cover h-64 w-full rounded"
        src={image.url}
        alt="Employers"
      />
      <button
        onClick={() => handleDelete(image.name)}
        className="absolute bottom-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600"
      >
        <MdDelete />
      </button>
    </div>
  );
};

export default ImageCard;
