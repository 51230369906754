import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Swal from "sweetalert2";
import { BASE_URL } from "../api/apiConfig";

const EmployerEditForm = () => {
    const { _id } = useParams(); // Get the ID parameter from the URL
    console.log("_id:", _id);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        console.log("_id:", _id);
        const fetchUserData = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/signup/emp-data/${_id}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch user data");
                }
                const data = await response.json();
                setUserData(data.user);
                console.log("Fetched user data:", data.user);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [_id]);

    console.log("userData:", userData);
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await fetch(
                `${BASE_URL}/signup/signup/update/${_id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(values),
                }
            );
            if (!response.ok) {
                throw new Error("Failed to update user");
            }
            const data = await response.json();
            console.log("User updated successfully:", data);
            // Redirect to a success page or navigate to another route
            Swal.fire({
                icon: "success",
                title: "User updated successfully",
            });
            navigate("/app-employer");
        } catch (error) {
            console.error("Error updating user:", error);
        } finally {
            setSubmitting(false);
        }
    };
    if (!userData) {
        return <div>Loading...</div>;
    }
    const handleSubmitConfirmation = (values, { setSubmitting }) => {
        Swal.fire({
            title: "Confirm Update",
            text: "Are you sure you want to update this user?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, update it!",
        }).then((result) => {
            if (result.isConfirmed) {
                handleSubmit(values, { setSubmitting });
            }
        });
    };
    return (
        <Formik
            initialValues={{
                fullName: userData.fullName,
                mobileNumber: userData.mobileNumber,
                address: userData.address,
                gender: userData.gender,
                email: userData.email,
                userRole: userData.userRole,
            }}
            onSubmit={handleSubmitConfirmation}
        >
            {({ isSubmitting, values, setFieldValue, resetForm }) => (
                <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                    <div className="flex justify-between">
                        <h2 className="text-xl font-bold mb-4 mt-5">
                            Update Information
                        </h2>
                        <NavLink to={`/app-employer`} className="mt-9 border-color">
                            Close
                        </NavLink>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label
                                htmlFor="fullName"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Name
                            </label>
                            <Field
                                type="text"
                                name="fullName"
                                className="form-input block w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-indigo-500"
                            />
                            <ErrorMessage
                                name="fullName"
                                component="div"
                                className="text-red-500 text-xs italic"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label
                                htmlFor="mobileNumber"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Mobile Number
                            </label>
                            <Field
                                type="text"
                                name="mobileNumber"
                                className="form-input block w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-indigo-500"
                                disabled={values.userRole}
                            />
                            <ErrorMessage
                                name="mobileNumber"
                                component="div"
                                className="text-red-500 text-xs italic"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label
                                htmlFor="address"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Address
                            </label>
                            <Field
                                type="text"
                                name="address"
                                className="form-input block w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-indigo-500"
                            />
                            <ErrorMessage
                                name="address"
                                component="div"
                                className="text-red-500 text-xs italic"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        {" "}
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-bold text-gray-700"
                            >
                                Email
                            </label>
                            <Field
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-indigo-500"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="text-red-500 text-xs italic"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className=" px-4 py-2 mt-6 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                    >
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default EmployerEditForm;
