import React, { createContext, useState, useEffect, useContext } from "react";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(
    localStorage.getItem("userRole") || ""
  );
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("isAuthenticated")) || false
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setUserRole(localStorage.getItem("userRole") || "");
      setToken(localStorage.getItem("token") || "");
      setAuthenticated(
        JSON.parse(localStorage.getItem("isAuthenticated")) || false
      );
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <UserDataContext.Provider
      value={{
        userRole,
        setUserRole,
        token,
        setToken,
        authenticated,
        setAuthenticated,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
