// import React, { useEffect, useState } from "react";
// import { AiOutlineMenu } from "react-icons/ai";
// import { BsChatLeft } from "react-icons/bs";
// import { RiNotification3Line } from "react-icons/ri";
// import { MdKeyboardArrowDown } from "react-icons/md";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// import profile from "../data/mp.png";
// import Modal from "./Modal";
// import { useStateContext } from "../contexts/ContextProvider";
// import { BASE_URL } from "../api/apiConfig";

// const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
//   <TooltipComponent content={title} position="BottomCenter">
//     <button
//       type="button"
//       onClick={() => customFunc()}
//       style={{ color }}
//       className="relative text-xl rounded-full p-3 hover:bg-light-gray"
//     >
//       <span
//         style={{ background: dotColor }}
//         className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
//       />
//       {icon}
//     </button>
//   </TooltipComponent>
// );

// const Navbar = () => {
//   const [isNotificationOpen, setIsNotificationOpen] = useState(false);
//   // const [notificationContent, setNotificationContent] = useState("");
//   const [notificationData, setNotificationData] = useState([]);

//   const fetchNotificationData = async () => {
//     try {
//       const response = await fetch(`${BASE_URL}/user/notifications`);
//       if (!response.ok) {
//         throw new Error("Failed to fetch user data");
//       }
//       const data = await response.json();
//       setNotificationData(data.notifications);
//       console.log("Fetched notification data:", data);
//     } catch (error) {
//       console.error("Error fetching notification data:", error);
//     }
//   };

//   useEffect(() => {
//     fetchNotificationData();
//   }, []);

//   // // Function to handle opening the notification modal with content
//   // const openNotificationModal = (content,data) => {
//   //   setNotificationContent(content);
//   //   setIsNotificationOpen(true);
//   // };

//   // // Function to close the notification modal
//   // const closeNotificationModal = () => {
//   //   setIsNotificationOpen(false);
//   //   // Optionally, you can clear the notification content here if needed
//   //   setNotificationContent("");
//   // };

//   // Function to handle opening the notification modal with content
//   const openNotificationModal = () => {
//     setIsNotificationOpen(true);
//   };

//   // Function to close the notification modal
//   const closeNotificationModal = () => {
//     setIsNotificationOpen(false);
//   };

//   const {
//     currentColor,
//     activeMenu,
//     setActiveMenu,
//     handleClick,
//     setScreenSize,
//     screenSize,
//   } = useStateContext();

//   useEffect(() => {
//     const handleResize = () => setScreenSize(window.innerWidth);

//     window.addEventListener("resize", handleResize);

//     handleResize();

//     return () => window.removeEventListener("resize", handleResize);
//   });

//   useEffect(() => {
//     const handleResize = () => setScreenSize(window.innerWidth);

//     window.addEventListener("resize", handleResize);

//     handleResize();

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [setScreenSize]);

//   useEffect(() => {
//     if (screenSize <= 900) {
//       setActiveMenu(false);
//     } else {
//       setActiveMenu(true);
//     }
//   }, [screenSize, setActiveMenu]);

//   const handleActiveMenu = () => setActiveMenu(!activeMenu);

//   return (
//     <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
//       <NavButton
//         title="Menu"
//         customFunc={handleActiveMenu}
//         color={currentColor}
//         icon={<AiOutlineMenu />}
//       />
//       <div className="flex">
//         <NavButton
//           title="Chat"
//           dotColor="#03C9D7"
//           customFunc={() => handleClick("chat")}
//           color={currentColor}
//           icon={<BsChatLeft />}
//         />
//         {/* <NavButton
//           title="Notification"
//           dotColor="rgb(254, 201, 15)"
//           customFunc={() => handleClick("notification")}
//           color={currentColor}
//           onClick={() => openNotificationModal("You have new notifications!")}
//           icon={<RiNotification3Line />}
//         /> */}

//         {/* Notification button */}
//         <TooltipComponent content="Notification" position="BottomCenter">
//           <button
//             title="Notification"
//             dotColor="rgb(254, 201, 15)"
//             customFunc={() => handleClick("notification")}
//             color={currentColor}
//             // onClick={() => openNotificationModal("You have new notifications!")}
//             onClick={openNotificationModal}
//             className="relative text-xl rounded-full p-3 hover:bg-light-gray"
//           >
//             <RiNotification3Line />
//           </button>
//         </TooltipComponent>

//         {/* Notification modal */}
//         <Modal
//           isOpen={isNotificationOpen}
//           onClose={closeNotificationModal}
//           title="Notification"
//         >
//           {/* {notificationContent} */}

//           <table className="table-auto w-full">
//             <tbody>
//               {notificationData.map((item, index) => (
//                 <tr key={index}>
//                   <td className="border px-4 py-2">{item.title}</td>
//                   <td className="border px-4 py-2">{item.count}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </Modal>

//         <TooltipComponent content="Profile" position="BottomCenter">
//           <div
//             className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
//             onClick={() => handleClick("userProfile")}
//           >
//             <img
//               className="rounded-full w-8 h-8 bg-white"
//               src={profile}
//               alt="user-profile"
//             />
//             <p>
//               <span className="text-gray-400 text-14">Hi,</span>{" "}
//               <span className="text-gray-400 font-bold ml-1 text-14">
//                 Mera Rojgar Mera Adhikar
//               </span>
//             </p>
//             <MdKeyboardArrowDown className="text-gray-400 text-14" />
//           </div>
//         </TooltipComponent>
//         {/*
//         {isClicked.cart && (<Cart />)}
//         {isClicked.chat && (<Chat />)}
//         {isClicked.notification && (<Notification />)}
//         {isClicked.userProfile && (<UserProfile />)} */}
//       </div>
//     </div>
//   );
// };

// export default Navbar;

import { Box, Menu, Typography, MenuItem, styled } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React, { useEffect, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BsChatLeft } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiNotification3Line } from "react-icons/ri";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { BASE_URL } from "../api/apiConfig";
import profile from "../data/mp.png";
import { links } from "../data/dummy";

const Navbar = () => {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const Component = styled(Menu)`
    margin-top: 5px;
  `;

  const Logout = styled(Typography)`
    font-size: 14px;
    margin-left: 10px;
  `;
  const fetchNotificationData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/user/notifications`);
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();
      setNotificationData(data.notifications);
      console.log("Fetched notification data:", data);
    } catch (error) {
      console.error("Error fetching notification data:", error);
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const openNotificationModal = () => {
    setIsNotificationOpen(true);
  };

  const closeNotificationModal = () => {
    setIsNotificationOpen(false);
  };

  const handleNotificationClick = async (title) => {
    try {
      if (title === "New Contractor Enquiry") {
        navigate("/contractor");
      } else if (title === "New Labor Enquiry") {
        navigate("/labour");
      } else if (title === "New Registrations Today") {
        navigate("/lead");
      }

      // Close the notification modal after redirection
      setIsNotificationOpen(false);

      // Update the notification count to 0 on the backend
      const updatedNotifications = notificationData.map((item) => {
        if (item.title === title) {
          return { ...item, count: 0 };
        }
        return item;
      });

      // Update state with the modified notification data (to reset the count)
      setNotificationData(updatedNotifications);

      // Send a PUT request to update the notification count in the backend
      await fetch(`${BASE_URL}/user/update-notification/${title}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ count: 0 }),
      });

      console.log(`Notification '${title}' count updated to 0.`);
    } catch (error) {
      console.error("Error updating notification count:", error);
    }
  };

  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    setScreenSize,
    screenSize,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    setActiveMenu(screenSize > 900);
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  const [open, setOpen] = useState(false);

  const handleClick2 = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear("isAuthenticated");
    localStorage.clear("token");
    localStorage.clear("userRole");
    window.location.reload();
  };

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      <TooltipComponent content="Menu" position="BottomCenter">
        <button
          type="button"
          onClick={handleActiveMenu}
          style={{ color: currentColor }}
          className="relative text-xl rounded-full p-3 hover:bg-light-gray"
        >
          <AiOutlineMenu />
        </button>
      </TooltipComponent>
      <div className="flex">
        <TooltipComponent content="Chat" position="BottomCenter">
          <button
            title="Chat"
            dotColor="#03C9D7"
            onClick={() => handleClick("chat")}
            style={{ color: currentColor }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
          >
            <BsChatLeft />
          </button>
        </TooltipComponent>
        <TooltipComponent content="Notification" position="BottomCenter">
          <button
            title="Notification"
            dotColor="rgb(254, 201, 15)"
            onClick={openNotificationModal}
            style={{ color: currentColor }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
          >
            <RiNotification3Line />
          </button>
        </TooltipComponent>
        <Modal
          isOpen={isNotificationOpen}
          onClose={closeNotificationModal}
          title="Notification"
        >
          <table className="table-auto w-full">
            <tbody>
              {notificationData.map((item, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{item.title}</td>
                  <td className="border px-4 py-2">{item.count}</td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleNotificationClick(item.title)}
                      className="text-blue-500 underline hover:text-blue-700"
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
        <Box onClick={handleClick2} style={{ position: "relative" }}>
          <Typography style={{ marginTop: 10, cursor: "pointer" }}>
            Hi, Mera Rozgar Mera Adhikar
          </Typography>
          <ArrowDropDownIcon
            style={{
              position: "absolute",
              left: "50%",
              top: 25,
              cursor: "pointer",
            }}
          />
        </Box>
        <Component
          aria-labelledby="demo-positioned-button"
          anchorEl={open}
          open={Boolean(open)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              logout();
            }}
          >
            <PowerSettingsNewIcon color="primary" fontSize="small" />
            <Logout>Logout</Logout>
          </MenuItem>
        </Component>
        {/* <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick("userProfile")}
          >
            <img
              className="rounded-full w-8 h-8 bg-white"
              src={profile}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{" "}
              <span className="text-gray-400 font-bold ml-1 text-14">
                Mera Rojgar Mera Adhikar
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent> */}
      </div>
    </div>
  );
};

export default Navbar;
