import { BASE_URL } from "./apiConfig";

export const fetchAllUserData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/user/all-userdata`);
    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    const data = await response.json();
    console.log("Fetched user data:", data.users);
    return data.users;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const handleStatusToChange = async (userId, updatedStatus) => {
  try {
    const response = await fetch(`${BASE_URL}/user/update/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: updatedStatus,
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to update status");
    }
    console.log("Status updated successfully for user:", userId);
  } catch (error) {
    console.error("Error updating status:", error);
    throw error;
  }
};

export const handleCommentUpdate = async (_id, comment) => {
  try {
    const response = await fetch(`${BASE_URL}/user/update/${_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comments: [
          {
            comment: comment,
            commentedAt: Date.now(),
          },
        ],
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to add comment");
    }
  } catch (error) {
    console.error("Error adding comment:", error);
    throw error;
  }
};

export const fetchUserComments = async (userId) => {
  try {
    const response = await fetch(`${BASE_URL}/user/user-data/${userId}`); // Adjust the URL according to your server's API endpoint for fetching comments
    if (!response.ok) {
      throw new Error("Failed to fetch user comments");
    }
    const data = await response.json();
    return data.comments; // Assuming the response contains an array of comments
  } catch (error) {
    console.error("Error fetching user comments:", error);
    throw error;
  }
};
