import React, { useEffect, useState } from "react";
import { VectorMap } from "react-jvectormap";
import { BASE_URL } from "../api/apiConfig";
const IndiaMap = () => {
  const [mapData, setMapData] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/stats/map-data`) // Adjust the URL according to your backend setup
      .then((response) => response.json())
      .then((data) => {
        setMapData(data);
      })
      .catch((error) => console.log("Error fetching map data:", error));

    console.log(mapData);
  }, []);

  const getdata = (key) => {
    const countryData = {};
    mapData.forEach(function (obj) {
      countryData[obj.code] = obj.value;
    });
    return countryData[key];
  };

  const getalldata = () => {
    const countryData = {};
    mapData.forEach(function (obj) {
      countryData[obj.code] = obj.value;
    });
    return countryData;
  };

  const handleshow2 = (e, el, code) => {
    el.html(el.html() + ` <br> Statics: ${getdata(code)}`);
  };

  return (
    <div>
      <VectorMap
        map={"in_mill"}
        backgroundColor="transparent"
        focusOn={{
          x: 0.5,
          y: 0.5,
          scale: 0,
          animate: false,
        }}
        zoomOnScroll={true}
        containerStyle={{
          width: "100%",
          height: "500px",
        }}
        onRegionClick={(e, countryCode) => console.log(countryCode)} // Get the country code
        onRegionTipShow={handleshow2}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: "#e4e4e4",
            "fill-opacity": 0.9,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
          selected: {
            fill: "#2938bc", // On click color of state
          },
        }}
        regionsSelectable={false}
        series={{
          regions: [
            {
              values: getalldata(), // Can be directly served with API response or any data
              scale: ["#C8EEFF", "#0071A4"], // Color range
              normalizeFunction: "polynomial",
            },
          ],
        }}
      />
    </div>
  );
};

export default IndiaMap;
